import React from 'react';
import { useFrame } from '@react-three/fiber';
import CustomShaderMaterial from './RectangleShaderMaterial';
import { Color } from 'three';

const Rectangle = ({ width, height, color, getModelMatrix }) => {
    const meshRef = React.useRef();
    useFrame(() => {
        if (meshRef.current && getModelMatrix) {
            const modelMatrix = meshRef.current.matrixWorld;
            getModelMatrix(modelMatrix);
        }
    });

    const material = React.useMemo(() => {
        const customMaterial = new CustomShaderMaterial();
        customMaterial.uniforms.uColor.value = new Color(color);
    }, []);

    return (
        <mesh ref={meshRef}>
            <planeGeometry args={[width, height]} />
            <customShaderMaterial
                ref={material}
                attach="material"
                transparent
                uniforms={{ uColor: { value: new Color(color) } }}
            />
        </mesh>
    );
};

export default Rectangle;
