import { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import Experience from './Experience';

export default function App() {
    const canvasRef = useRef(null);

    function handleDoubleClick() {
        const canvas = canvasRef.current;
        if (!document.fullscreenElement) {
            canvas.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    }

    return (
        <Canvas
            shadows
            onDoubleClick={handleDoubleClick}
            ref={canvasRef}
            dpr={window.devicePixelRatio}>
            <Experience />
        </Canvas>
    );
}
