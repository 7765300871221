import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const client = axios.create({
    baseURL: 'https://tfbm-media.adler3d.com',
    headers: {
        Accept: 'model/gltf-binary',
        'Content-Type': 'model/gltf-binary',
    },
});

client.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        let res = error.response;
        console.log(res);
        return Promise.reject(error);
    }
);

const ModelService = {
    useLoadModel: () =>
        useQuery({
            queryKey: 'fetchingModel',
            queryFn: async () => {
                return await client.get(`/TimesSquareFinal.glb`);
            },
            staleTime: 50 * (60 * 1000), // 50 mins
            cacheTime: 55 * (60 * 1000), // 55 mins
        }),
};

export default ModelService;
