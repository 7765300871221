export const RANDOM_MESSAGE = [
    'Love for Korean Traditional Culture',
    'UP Board Class 12th Result 2023',
    'India up',
    'I need followers',
    "Hello Newyork!  I'm Aeong",
    "Hi I'm zia",
    "Don't know what to write there",
    'Happy ',
    'The world belongs to mankind so, anywhere you found yourself established and exploit for a while and die to meet God. Stop racism. Let there be peace, freedom and iniquqlity to everyone.',
    'I am pakistaniii person...',
    'I want a job',
    ' 10th class result ',
    'Freedom ',
    'Need ',
    'https://www.facebook.com/rusika.chaudhary.71?mibextid=ZbWKwL',
    'Hey, lm new here',
    'Give me money',
    'I am interested ',
    'Will one day make us good sculptors ',
    'I am sure i will do my best ',
    'Beautiful ',
    'I love to be in Canada and work there too with my family',
    'আমার বুকের ভিতর দুঃখ একটা ঘর বানাইছে রে!!😔💔🥀',
    'Man of wisdom',
    'Someone to chat with',
    'Philippines ',
    'Love',
    '고장',
    'Bonjour2023',
    'www.burlingyonenglish.in',
    "U17 woman's football asian qualifire",
    'Am south Sudanese 29 year old,University graduate ',
    'Never look down someone ',
    'Better for me ',
    "Download the world's leading B2B App to shop millions of products, find worldwide suppliers, and get exclusive in-app discounts!",
    'I love you guys ',
    'I will be grateful  if I have  this scholarship ',
    'I am not sure how ',
    'Truck Driver Demand Rate  You can expect a strong, stable, and long-lasting career as a truck driver in Canada.' +
        'Truck driving is an excellent job; it is a noble profession in Canada, making it one of the most in-demand careers.  Advertisements',
    'Beauty of the world',
    'I need a soul mate please help me to have one',
    'I love every movement',
    'Hello World',
    'Listen to the story of a whale and a girl who dreams of eternity.',
    "Let's have a fairy tale day",
    'art is hunger',
    '음악으로 그려낸 나만의 세상이 타임스퀘어를 점령했어요!',
    'Imagine the future, but remember the past!',
    'Time is gold',
    "Hello, there! I'm here.",
    'Unbelievable ',
    '사랑해요',
    'Dokdo is our land !',
    '와, 뉴욕이다',
    'Anything ',
    'Vitality of human inside',
    'Xxx and the rest of us have a great day xxxcx xxxcxx',
    'THIS Is My Page',
    'My time very importan',
    '3224117713',
    'Ar',
    'Yash Kumar ',
    'Asad hussain',
    'Ainuching ',
    'Aman',
    'Asif khan ',
    'Lucknow ',
    'mackson2002',
    'Am interested',
    '9112004ritesh@gmail.com',
    'Kwesi is everywhere ',
    'Tabiya',
    'Pørïtõsh Rõy',
    'Looking for job',
    '480411',
    '123456',
    'Kenya',
    '  rustom2222',
    'Right ',
    'Sut sutrisno',
    'Sufyan',
    'Abir',
    '24 hour',
    '미소를 잃지 않는 사랑스러운 벨루가의 진정한 행복을 바랍니다.',
    'My blood pressure is very low',
    'একটু ঘুরাঘুরি! ♥️♥️',
    '안녕하세요 ',
    'Power of individuality ',
    '반가워요',
    'the cross the world',
    '뉴욕 정ㅋ벅ㅋ',
    '자연이 살아야 인간이 삽니다.',
    'Now, it’s time for K-Art!',
    "I'll be the center of the world.",
    'we are all one live, love and be together',
    '잠들지 않는 도시에 흔적을 남기다.',
    'I just want to make my family proud and have a happy life and I want to make my mama proud',
    "I'M. LUCKY THAT I'II MEET YOU",
    'Art is not just a reflection of the world, but a force that can change it. Explore, create and preserve the beauty of our world.',
    'Life should be a cocktail of Fantasy and Reality',
    'It’s a gift to be simple, it’s a gift to be free',
    'wow, mum look, my art is on Times Square',
    '« Mom, Dad, I’m in Times Square !!! »',
    'interesting project',
    'open your eyes and look around, you will see your way!',
];
