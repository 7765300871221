import { useEffect, useRef } from 'react';
import { useGLTF, useAnimations, MeshReflectorMaterial } from '@react-three/drei';
import { LoopOnce } from 'three';

import TimesSquare11_NonCurtain from './TimesSquare11_NonCurtain.glb';

export default function TimesSquareNonCurtain(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(TimesSquare11_NonCurtain);
    const { actions } = useAnimations(animations, group);

    useEffect(() => {
        const playAnimations = () => {
            Object.keys(actions).filter((s) => (s.includes("Key") && !s.includes("Camera"))).forEach((key) => {
                actions[key].setLoop(LoopOnce).reset().play();
            });
        };

        Object.keys(actions).filter((s) => (s.includes("Key") && !s.includes("Camera"))).forEach((key) => {
            actions[key].clampWhenFinished = true;
        });
        playAnimations();

        const handleResize = () => {
            playAnimations();
        };

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                playAnimations();
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [actions]);

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                {/* <PerspectiveCamera
                    name="Camera"
                    makeDefault={true}
                    far={1000}
                    near={0.1}
                    fov={26.99}
                    position={[-9.91, -1.81, 6.01]}
                    rotation={[0.58, -0.94, 0.47]}
                    scale={0.76}
                /> */}
                <group name="Text046">
                    <mesh
                        name="Text002"
                        castShadow
                        receiveShadow
                        geometry={nodes.Text002.geometry}
                        material={materials['Material.057']}
                        morphTargetDictionary={nodes.Text002.morphTargetDictionary}
                        morphTargetInfluences={nodes.Text002.morphTargetInfluences}
                    />
                    <mesh
                        name="Text002_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Text002_1.geometry}
                        material={materials['Material.081']}
                        morphTargetDictionary={nodes.Text002_1.morphTargetDictionary}
                        morphTargetInfluences={nodes.Text002_1.morphTargetInfluences}
                    />
                </group>
                <mesh
                    name="frame003"
                    castShadow
                    receiveShadow
                    geometry={nodes.frame003.geometry}
                    material={materials['Material.007']}
                    morphTargetDictionary={nodes.frame003.morphTargetDictionary}
                    morphTargetInfluences={nodes.frame003.morphTargetInfluences}
                />
                <mesh
                    name="Cube002"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cube002.geometry}
                    material={materials['Material.012']}
                    morphTargetDictionary={nodes.Cube002.morphTargetDictionary}
                    morphTargetInfluences={nodes.Cube002.morphTargetInfluences}
                />
                <mesh
                    name="wall"
                    castShadow
                    receiveShadow
                    geometry={nodes.wall.geometry}
                    material={materials['Material.009']}
                    morphTargetDictionary={nodes.wall.morphTargetDictionary}
                    morphTargetInfluences={nodes.wall.morphTargetInfluences}>
                    {/* <MeshReflectorMaterial mixBlur={1.0} mixStrength={0.2} resolution={1024} /> */}
                </mesh>
                <mesh
                    name="wall001"
                    castShadow
                    receiveShadow
                    geometry={nodes.wall001.geometry}
                    material={materials['Material.002']}
                    morphTargetDictionary={nodes.wall001.morphTargetDictionary}
                    morphTargetInfluences={nodes.wall001.morphTargetInfluences}
                />
                <mesh
                    name="wall004"
                    castShadow
                    receiveShadow
                    geometry={nodes.wall004.geometry}
                    material={materials['Material.054']}
                    morphTargetDictionary={nodes.wall004.morphTargetDictionary}
                    morphTargetInfluences={nodes.wall004.morphTargetInfluences}
                />
                <group name="Curve">
                    <mesh
                        name="Curve_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Curve_1.geometry}
                        material={materials['Material.024']}
                        morphTargetDictionary={nodes.Curve_1.morphTargetDictionary}
                        morphTargetInfluences={nodes.Curve_1.morphTargetInfluences}
                    />
                    <mesh
                        name="Curve_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.Curve_2.geometry}
                        material={materials['Material.023']}
                        morphTargetDictionary={nodes.Curve_2.morphTargetDictionary}
                        morphTargetInfluences={nodes.Curve_2.morphTargetInfluences}
                    />
                    <mesh
                        name="Curve_3"
                        castShadow
                        receiveShadow
                        geometry={nodes.Curve_3.geometry}
                        material={materials['Material.026']}
                        morphTargetDictionary={nodes.Curve_3.morphTargetDictionary}
                        morphTargetInfluences={nodes.Curve_3.morphTargetInfluences}
                    />
                </group>
                <group name="pic004">
                    <mesh
                        name="큐브005"
                        castShadow
                        receiveShadow
                        geometry={nodes.큐브005.geometry}
                        material={materials['Picframe.001']}
                        morphTargetDictionary={nodes.큐브005.morphTargetDictionary}
                        morphTargetInfluences={nodes.큐브005.morphTargetInfluences}
                    />
                    <mesh
                        name="큐브005_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.큐브005_1.geometry}
                        material={materials['Material.005']}
                        morphTargetDictionary={nodes.큐브005_1.morphTargetDictionary}
                        morphTargetInfluences={nodes.큐브005_1.morphTargetInfluences}
                    />
                </group>
                <mesh
                    name="wall003"
                    castShadow
                    receiveShadow
                    geometry={nodes.wall003.geometry}
                    material={materials['Material.025']}
                    morphTargetDictionary={nodes.wall003.morphTargetDictionary}
                    morphTargetInfluences={nodes.wall003.morphTargetInfluences}
                />
                <group name="wall006" position={[-1.61, 3.49, 1]}>
                    <mesh
                        name="Cube015"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube015.geometry}
                        material={materials['Material.021']}
                    />
                    <mesh
                        name="Cube015_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube015_1.geometry}
                        material={materials['Material.028']}
                    />
                    <mesh
                        name="Cube015_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube015_2.geometry}
                        material={materials['Material.029']}
                    />
                    <mesh
                        name="Cube015_3"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube015_3.geometry}
                        material={materials['Material.030']}
                    />
                </group>
                <group name="wall009" position={[-1.61, 3.49, 1]}>
                    <mesh
                        name="Cube012"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube012.geometry}
                        material={materials['Material.014']}
                    />
                    <mesh
                        name="Cube012_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube012_1.geometry}
                        material={materials['Material.032']}
                    />
                    <mesh
                        name="Cube012_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube012_2.geometry}
                        material={materials['Material.033']}
                    />
                    <mesh
                        name="Cube012_3"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube012_3.geometry}
                        material={materials['Material.022']}
                    />
                </group>
                <group name="wall008" position={[-1.61, 3.49, 1]}>
                    <mesh
                        name="Cube016"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube016.geometry}
                        material={materials['Material.035']}
                    />
                    <mesh
                        name="Cube016_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube016_1.geometry}
                        material={materials['Material.039']}
                    />
                    <mesh
                        name="Cube016_2"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube016_2.geometry}
                        material={materials['Material.040']}
                    />
                    <mesh
                        name="Cube016_3"
                        castShadow
                        receiveShadow
                        geometry={nodes.Cube016_3.geometry}
                        material={materials['Material.041']}
                    />
                </group>
                <mesh
                    name="Plane003"
                    castShadow
                    receiveShadow
                    geometry={nodes.Plane003.geometry}
                    material={materials['Material.003']}
                    morphTargetDictionary={nodes.Plane003.morphTargetDictionary}
                    morphTargetInfluences={nodes.Plane003.morphTargetInfluences}
                />
                <group name="pic001">
                    <mesh
                        name="큐브006"
                        castShadow
                        receiveShadow
                        geometry={nodes.큐브006.geometry}
                        material={materials['Picframe.001']}
                        morphTargetDictionary={nodes.큐브006.morphTargetDictionary}
                        morphTargetInfluences={nodes.큐브006.morphTargetInfluences}
                    />
                    <mesh
                        name="큐브006_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.큐브006_1.geometry}
                        material={materials['Material.006']}
                        morphTargetDictionary={nodes.큐브006_1.morphTargetDictionary}
                        morphTargetInfluences={nodes.큐브006_1.morphTargetInfluences}
                    />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload(TimesSquare11_NonCurtain);
