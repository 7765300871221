import './style.css';
import ReactDOM from 'react-dom/client';
import App from './base/App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(document.querySelector('#root'));
const queryClient = new QueryClient();

root.render(
    <QueryClientProvider client={queryClient}>
        <App />
    </QueryClientProvider>
);
