import client from '../client';
import { useMutation, useQuery } from '@tanstack/react-query';

const StatusService = {
    useStartService: () =>
        useMutation({
            mutationFn: async () => {
                return await client.post(`/service/start`);
            },
        }),
    useStopService: async () => {
        return await client.post(`/service/stop`);
    },
    useGetStatus: (successFn) =>
        useQuery({
            queryKey: ['fetchingStatus'],
            queryFn: async () => {
                return await client.get(`/service/status`);
            },
            refetchInterval: 100,
            onSuccess: successFn,
        }),
};

export default StatusService;
