import { isSimplified, isTraditional } from 'traditional-or-simplified';
import unicodeNsm from './UnicodeNsm';
import { RANDOM_MESSAGE } from '../constants/variables';

const getCharacterCount = str => {
    const chars = [...str];
    let count = 0;
    for (const char of chars) if (!unicodeNsm.includes(escape(char))) count++;
    return count;
};

const getLanguage = text => {
    const isHebrew = text => {
        // using a regular expression to match Hebrew characters
        let hebrewChars = /[\u0590-\u05FF]/;
        for (let i = 0; i < text.length; i++)
            if (hebrewChars.test(text.charAt(i))) return true;
        return false;
    };
    const isTamil = str => {
        const tamilRegex = new RegExp(/^[\u0B80-\u0BFF]+$/);
        return tamilRegex.test(str);
    };
    const isEthiopic = str => {
        for (const char of str) {
            const code = char.charCodeAt(0);
            if (code >= 0x1200 && code <= 0x137f) {
                return true;
            }
        }
        return false;
    };
    const isBengali = str => {
        const bengaliRegex = /[\u0980-\u09FF]/;
        return bengaliRegex.test(str);
    };

    if (/[\uac00-\ud7a3]/g.test(text)) return 'korean';
    if (text.match(/[\u3040-\u30FF-\uFF66-\uFF9F]/)) return 'japanese';
    if (text.match(/[\u0E00-\u0E7F]/)) return 'thai';
    if (/[\u0900-\u097F]/.test(text)) return 'hindi';
    if (/[\u0600-\u06FF]/.test(text)) return 'arabic';
    if (isHebrew(text)) return 'hebrew';
    if (isTamil(text)) return 'tamil';
    if (isEthiopic(text)) return 'ethiopic';
    if (isBengali(text)) return 'bengali';

    // remove english punctuation marks because isTraditional and isSimplified
    // will cause an error when they encounter them
    const symbolsRegex = /[!"#$%&'()*+,-./:;<=>?@\\[]^_`{|}~]/g;
    const afterSymbolsRemoved = text.replace(symbolsRegex, '');
    if (afterSymbolsRemoved.length > 0) {
        if (isTraditional(afterSymbolsRemoved)) return 'chineseTraditional';
        if (isSimplified(afterSymbolsRemoved)) return 'chineseSimplified';
    }

    return 'other';
};

const getCharacterWidth = (char, language, scale = 1.0) => {
    const thinnerEnglishPunctuation = char =>
        char === ' ' ||
        char === '-' ||
        /[!",./:;[\]`{|}\u00A0\u202F\u205F]/.test(char);
    const thinEnglishPunctuation = char => /['()]/.test(char);
    const fatEnglishPunctuation = char => /[*/<>?\\]/.test(char);
    const fatterEnglishPunctuation = char => /[$%&^_~=#+]/.test(char);
    const fattestEnglishPunctuation = char => /[@]/.test(char);

    if (unicodeNsm.includes(escape(char))) return 0;

    if (/[0-9]/.test(char)) return scale * 0.111;
    if (thinnerEnglishPunctuation(char)) return scale * 0.065;
    if (thinEnglishPunctuation(char)) return scale * 0.07;
    if (fatEnglishPunctuation(char)) return scale * 0.09;
    if (fatterEnglishPunctuation(char)) return scale * 0.105;
    if (fattestEnglishPunctuation(char)) return scale * 0.1825;

    if (/\p{Emoji}/gu.test(char)) return scale * 0.21;
    if (/[fijlI]/.test(char)) return scale * 0.07;
    if (/[abcdehknopqrstuvwxyz]/.test(char)) return scale * 0.111;
    if (/[gmABCDEFGHJKLMNOPQRSTUVWXYZ]/.test(char)) return scale * 0.13;

    if (
        language === 'thai' ||
        language === 'hindi' ||
        language === 'tamil' ||
        language === 'ethiopic'
    )
        return scale * 0.125;
    if (language === 'japanese') return scale * 0.205;
    if (language === 'chineseTraditional' || language === 'chineseSimplified')
        return scale * 0.205;
    if (language === 'arabic') return scale * 0.1;
    if (language === 'hebrew') return scale * 0.09;
    if (language === 'bengali') return scale * 0.13;
    if (language === 'other') return scale * 0.15;
    return scale * 0.19;
};

const getRandomColor = () => {
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += Math.floor(Math.random() * 10);
    }
    return color;
};

const getRandomText = () => {
    let arr = new Array('50');
    return arr.map((_, i) => {
        const randomIndex = Math.floor(Math.random() * RANDOM_MESSAGE.length);
        arr[i] = RANDOM_MESSAGE[randomIndex];
        return arr[i];
    });
};

export {
    getCharacterCount,
    getCharacterWidth,
    getLanguage,
    getRandomColor,
    getRandomText,
};
