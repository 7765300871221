import React, { useEffect, useRef } from 'react';
import { useGLTF, useAnimations, MeshReflectorMaterial } from '@react-three/drei';
import { LoopOnce } from 'three';
import ModelService from '../api/services/Model';
import TimesSquareNonCurtain from './TimesSquareNonCurtain';
import TimesSquareCurtain from './TimesSquareCurtain';

export default function TimeSquareFinal(props) {
    return (
        <group {...props} dispose={null}>
            <TimesSquareNonCurtain />
            <TimesSquareCurtain />
        </group>
    );
}

// export default function TimeSquareFinal(props) {
//     const group = useRef();

//     const { nodes, materials, animations } = useGLTF(
//         'https://tfbm-media.adler3d.com/TimesSquareFinal.glb'
//     );
//     const { actions } = useAnimations(animations, group);

//     useEffect(() => {
//         // Slightly worse performance
//         // Object.keys(actions).filter((s) => s.includes("Key")).forEach((key) => {
//         //     actions[key].clampWhenFinished = true;
//         //     actions[key].setLoop(LoopOnce).play();
//         // });

//         // console.log(Object.keys(actions).filter((s) => s.includes("Key")));
//         // 'KeyAction', 'Key.002Action.001', 'Key.001Action', 'Key.003Action'

//         actions['KeyAction'].clampWhenFinished = true;
//         actions['KeyAction'].setLoop(LoopOnce).play();

//         actions['Key.002Action.001'].clampWhenFinished = true;
//         actions['Key.002Action.001'].setLoop(LoopOnce).play();

//         actions['Key.001Action'].clampWhenFinished = true;
//         actions['Key.001Action'].setLoop(LoopOnce).play();

//         actions['Key.003Action'].clampWhenFinished = true;
//         actions['Key.003Action'].setLoop(LoopOnce).play();
//     }, []);

//     return (
//         <group ref={group} {...props} dispose={null}>
//             <group name="Scene">
//                 <mesh
//                     name="Plane004"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Plane004.geometry}
//                     material={nodes.Plane004.material}
//                     position={[1.68, 2.17, 0.78]}
//                 />
//                 <group name="frame002" position={[1.73, 2.17, 0]}>
//                     <mesh
//                         name="큐브001"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브001.geometry}
//                         material={materials["Material.001"]}
//                     />
//                     <mesh
//                         name="큐브001_1"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브001_1.geometry}
//                         material={materials["Material.006"]}
//                     />
//                 </group>
//                 <group name="Text046" position={[-1.85, 3.18, -0.47]}>
//                     <mesh
//                         name="Text002"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.Text002.geometry}
//                         material={materials["Material.057"]}
//                     />
//                     <mesh
//                         name="Text002_1"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.Text002_1.geometry}
//                         material={materials["Material.081"]}
//                     />
//                 </group>
//                 <group name="pic004" position={[1.74, 2.17, 0]}>
//                     <mesh
//                         name="큐브002"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브002.geometry}
//                         material={materials.Picframe}
//                     />
//                     <mesh
//                         name="큐브002_1"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브002_1.geometry}
//                         material={materials["Material.058"]}
//                     />
//                 </group>
//                 <mesh
//                     name="wall"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.wall.geometry}
//                     material={materials["Material.009"]}
//                     position={[0.74, 1.74, -0.74]}
//                 >
//                     <MeshReflectorMaterial
//                         mixBlur={1.0}
//                         mixStrength={0.2}
//                         resolution={1024}
//                     />
//                 </mesh>
//                 <mesh
//                     name="wall003"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.wall003.geometry}
//                     material={materials["Material.025"]}
//                     morphTargetDictionary={nodes.wall003.morphTargetDictionary}
//                     morphTargetInfluences={nodes.wall003.morphTargetInfluences}
//                     position={[1.61, 0.03, -1]}
//                 />
//                 <mesh
//                     name="Cube006"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Cube006.geometry}
//                     material={materials["Material.004"]}
//                     position={[1.71, 0.89, 0.78]}
//                 />
//                 <mesh
//                     name="wall002"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.wall002.geometry}
//                     material={materials["Material.054"]}
//                     position={[0.02, 3.46, -0.02]}
//                 />
//                 <mesh
//                     name="wall001"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.wall001.geometry}
//                     material={materials["Material.002"]}
//                     position={[-0.37, 1.77, 0.43]}
//                 />
//                 <mesh
//                     name="Plane002"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Plane002.geometry}
//                     material={nodes.Plane002.material}
//                     position={[1.43, 1.91, 0.67]}
//                     rotation={[-Math.PI, 0, 2.53]}
//                     scale={[-0.01, -0.03, -0.03]}
//                 />
//                 <mesh
//                     name="Plane001"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Plane001.geometry}
//                     material={materials["Material.003"]}
//                     morphTargetDictionary={nodes.Plane001.morphTargetDictionary}
//                     morphTargetInfluences={nodes.Plane001.morphTargetInfluences}
//                     position={[0.19, 0, 0]}
//                     scale={[1.1, 1, 1]}
//                 />
//                 <mesh
//                     name="Plane003"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Plane003.geometry}
//                     material={materials["Material.003"]}
//                     morphTargetDictionary={nodes.Plane003.morphTargetDictionary}
//                     morphTargetInfluences={nodes.Plane003.morphTargetInfluences}
//                     position={[0.46, -0.39, 0]}
//                     scale={[1.22, 1.11, 1.02]}
//                 />
//                 <mesh
//                     name="wall010"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.wall010.geometry}
//                     material={materials["Material.054"]}
//                     position={[0.05, 3.47, -0.05]}
//                 />
//                 <mesh
//                     name="wall005"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.wall005.geometry}
//                     material={materials["Material.054"]}
//                     position={[0.05, 3.47, -0.07]}
//                 />
//                 <group name="frame001" position={[1.73, 0.93, 0]}>
//                     <mesh
//                         name="큐브003"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브003.geometry}
//                         material={materials["Material.001"]}
//                     />
//                     <mesh
//                         name="큐브003_1"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브003_1.geometry}
//                         material={materials["Material.006"]}
//                     />
//                 </group>
//                 <group name="pic001" position={[1.74, 0.93, 0]}>
//                     <mesh
//                         name="큐브004"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브004.geometry}
//                         material={materials.Picframe}
//                     />
//                     <mesh
//                         name="큐브004_1"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.큐브004_1.geometry}
//                         material={materials["Material.038"]}
//                     />
//                 </group>
//                 <mesh
//                     name="Cube004"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Cube004.geometry}
//                     material={materials["Material.004"]}
//                     position={[1.71, 2.17, 0.78]}
//                 />
//                 <mesh
//                     name="Cube005"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Cube005.geometry}
//                     material={materials["Material.004"]}
//                     position={[1.71, 2.17, -0.78]}
//                 />
//                 <mesh
//                     name="Cube007"
//                     castShadow
//                     receiveShadow
//                     geometry={nodes.Cube007.geometry}
//                     material={materials["Material.004"]}
//                     position={[1.71, 0.89, -0.78]}
//                 />
//                 <group name="Plane" scale={[1, 1, 1.01]}>
//                     <mesh
//                         name="Plane001_1"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.Plane001_1.geometry}
//                         material={materials["Material.010"]}
//                         morphTargetDictionary={nodes.Plane001_1.morphTargetDictionary}
//                         morphTargetInfluences={nodes.Plane001_1.morphTargetInfluences}
//                     />
//                     <mesh
//                         name="Plane001_2"
//                         castShadow
//                         receiveShadow
//                         geometry={nodes.Plane001_2.geometry}
//                         material={materials["Material.011"]}
//                         morphTargetDictionary={nodes.Plane001_2.morphTargetDictionary}
//                         morphTargetInfluences={nodes.Plane001_2.morphTargetInfluences}
//                     />
//                 </group>
//             </group>
//         </group>
//     );
// }

// useGLTF.preload('https://tfbm-media.adler3d.com/TimesSquareFinal.glb');
