import { useCallback, useEffect, useRef, useState } from 'react';
import Message from '../components/Message';
import { StatusService, TextService } from '../api';
import { getCharacterCount, getCharacterWidth, getRandomColor, getLanguage, getRandomText } from '../utils/text-utils';

export default function MessageList() {
    const messageIdRef = useRef(0);
    const messageQueueRef = useRef([]);
    const [messageQueue, setMessageQueue] = useState([]);
    const [removeMessageCalled, setRemoveMessageCalled] = useState(false);
    const [currentTime, setCurrentTime] = useState(60);
    const [nextTime, setNextTime] = useState(currentTime - 3);
    const [isRandom, setIsRandom] = useState(false);

    // queue for messages that are waiting to be added to the message queue
    const waitQueueRef = useRef([]);

    // maximum limit of the y coordinate
    const Y_MIN = 0.2;
    const Y_MAX = 3.0;

    // maximum left and right limit of the x coordinate
    const X_MIN = -1.7;
    const X_MAX = 1.7;

    // width and height of printable wall space
    const WALL_SPACE_WIDTH = Math.abs(X_MIN) + X_MAX;
    const WALL_SPACE_HEIGHT = Y_MAX - Y_MIN;

    const padding = 0.2;
    const MAX_MESSAGE_BYTES = 30;

    let timeOutRef = useRef();
    const handleUsersTimeOut = useCallback(numOfUser => {
        if (numOfUser <= 100) {
            timeOutRef.current = 15000;
        } else if (numOfUser > 100 && numOfUser < 300) {
            timeOutRef.current = 10000;
        } else {
            timeOutRef.current = 5000;
        }
    }, []);

    const handleFetchStatus = useCallback(res => {
        if (res.data.status === 'running') {
            setCurrentTime(res.data['time-remaining']);
        } else {
            setCurrentTime(0);
        }
    }, []);

    const handleFetchText = async text => {
        if (text.data.length > 0) {
            setIsRandom(false);
            handleUsersTimeOut(text.data.length);
            text.data.forEach(item => {
                addMessage(item.text, false);
            });
        } else {
            setIsRandom(true);
            setTimeout(() => {
                setNextTime(currentTime);
            }, 3000);
        }
    };

    StatusService.useGetStatus(handleFetchStatus);
    TextService.useGetText(handleFetchText, new URL(window.location.href).searchParams.get('accessKey'));

    const { mutate: startStatus } = StatusService.useStartService();

    const checkEmptySpace = useCallback(
        message => {
            // ==================================================================
            // Calculate the x and y positions for the new message
            // ==================================================================
            const defaultX = X_MIN;
            const defaultY = Y_MAX;

            const messageHeight = 0.15;

            let messageWidth = 0;
            for (const char of message) messageWidth += getCharacterWidth(char, getLanguage(message));

            // scale down to fit the message to the wall
            let scale = 1.0;
            if (messageWidth > WALL_SPACE_WIDTH) {
                scale = WALL_SPACE_WIDTH / messageWidth;
                messageWidth = WALL_SPACE_WIDTH;
            }

            // Default spot for the new message on the wall
            let x = defaultX;
            let y = defaultY;

            let foundSpot = false;
            let i = 0;
            while (!foundSpot) {
                // wall full, add the first message to wait queue
                if (i > 500) {
                    return false;
                }

                // Get left and right boundaries of the new message
                const leftBoundary = x;
                const rightBoundary = x + messageWidth;

                // Check if the message box would overlap with any existing messages
                const overlap = messageQueueRef.current.some(
                    otherMessage =>
                        // left boundary of the new message box is within the boundaries of the other message box
                        leftBoundary <= otherMessage.rightBoundary + padding &&
                        rightBoundary >= otherMessage.x &&
                        // y is within the boundaries of the other message box
                        y - messageHeight <= otherMessage.y &&
                        y >= otherMessage.y - messageHeight,
                );

                // const withinWallSpace = leftBoundary >= X_MIN && rightBoundary <= X_MAX && y <= Y_MAX && y - messageHeight >= Y_MIN;
                const outsideWallSpace =
                    leftBoundary < X_MIN || rightBoundary > X_MAX || y > Y_MAX || y - messageHeight < Y_MIN;

                // If there's no overlap, we've found a spot for the new message
                if (!overlap && !outsideWallSpace) {
                    foundSpot = true;
                } else {
                    // Otherwise, move the message box to the right and check again
                    x += 0.1;
                    if (leftBoundary >= X_MAX || rightBoundary >= X_MAX) {
                        // If we've reached the right edge of the wall, move the box down to the next row
                        x = defaultX;
                        y -= messageHeight;
                    }
                    // We've reached the bottom row
                    if (y < Y_MIN) {
                        // reset to default Y position
                        y = defaultY;
                    }
                }
                i++;
            }

            return true;
        },
        [WALL_SPACE_WIDTH, X_MIN],
    );

    const validateMessage = message => {
        let validatedMessage = message;
        while (new Blob([validatedMessage]).size > MAX_MESSAGE_BYTES) {
            // remove the last character until the message is less than max allowed bytes
            const charRegex = /.$/u;
            validatedMessage = validatedMessage.slice(0, -1).replace(charRegex, '');
        }
        return validatedMessage;
    };

    const addMessage = useCallback(
        async (rawMessage, isFromWaitQueue = false) => {
            if (!rawMessage) return;

            const message = validateMessage(rawMessage);

            if (waitQueueRef.current.length > 0 && !isFromWaitQueue) {
                waitQueueRef.current.push(message);
                return;
            }

            const language = getLanguage(message);

            // ==================================================================
            // Calculate the x and y positions for the new message
            // ==================================================================
            const defaultX = X_MIN;
            const defaultY = Y_MAX;

            const messageHeight = 0.15;

            // scale down to fit the message to the wall
            let scale = 1.0;
            // if (new Blob([message]).size <= 10) {
            //     scale = 1.2;
            // } else if (new Blob([message]).size >= 20 && new Blob([message]).size <= 30) {
            //     scale = 0.8;
            // }
            if (getCharacterCount(message) <= 10 || new Blob([message]).size <= 10) {
                scale = 1.2;
            } else if (new Blob([message]).size >= 20 && new Blob([message]).size <= 30) {
                scale = 0.8;
            }

            let messageWidth = 0;
            for (const char of message) messageWidth += getCharacterWidth(char, language, scale);

            if (messageWidth > WALL_SPACE_WIDTH) {
                scale = WALL_SPACE_WIDTH / messageWidth;
                messageWidth = WALL_SPACE_WIDTH;
            }

            // Default spot for the new message on the wall
            let x = defaultX;
            let y = defaultY;

            let foundSpot = false;
            let i = 0;
            while (!foundSpot) {
                // console.log('finding spot...');
                // console.log('i:', i);
                // wall full, add the first message to wait queue
                if (i > 500) {
                    if (waitQueueRef.current.length === 0) {
                        // Wall is full and wait queue is empty, add message to wait queue
                        // console.log(`wall full, message added to wait queue: ${message}`);
                        waitQueueRef.current.push(message);
                        i = 0;
                        return;
                    } else if (isFromWaitQueue) {
                        // Message is from the wait queue but doesn't fit,
                        // add message back to wait queue as the first index
                        waitQueueRef.current.unshift(message);
                        i = 0;
                        return;
                    }
                }

                // Get left and right boundaries of the new message
                const leftBoundary = x;
                const rightBoundary = x + messageWidth;

                // Check if the message box would overlap with any existing messages
                const overlap = messageQueueRef.current.some(
                    otherMessage =>
                        // left boundary of the new message box is within the boundaries of the other message box
                        leftBoundary <= otherMessage.rightBoundary + padding &&
                        rightBoundary >= otherMessage.x &&
                        // y is within the boundaries of the other message box
                        y - messageHeight <= otherMessage.y &&
                        y >= otherMessage.y - messageHeight,
                );

                // const withinWallSpace = leftBoundary >= X_MIN && rightBoundary <= X_MAX && y <= Y_MAX && y - messageHeight >= Y_MIN;
                const outsideWallSpace =
                    leftBoundary < X_MIN || rightBoundary > X_MAX || y > Y_MAX || y - messageHeight < Y_MIN;

                // If there's no overlap, we've found a spot for the new message
                if (!overlap && !outsideWallSpace) {
                    foundSpot = true;
                } else {
                    // Otherwise, move the message box to the right and check again
                    x += 0.1;
                    if (leftBoundary >= X_MAX || rightBoundary >= X_MAX) {
                        // If we've reached the right edge of the wall, move the box down to the next row
                        x = defaultX;
                        y -= messageHeight;
                    }
                    // We've reached the bottom row
                    if (y < Y_MIN) {
                        // reset to default Y position
                        y = defaultY;

                        // scrapped this part because it was causing the message to disappear
                        // // If we've reached the bottom row, we can't find a spot for the message
                        // // and we exit the loop without adding it to the message list
                        // return null;
                    }
                }
                i++;
            }

            const rightBoundary = x + messageWidth;

            const newMessage = {
                key: messageIdRef.current,
                message: message,
                language: language,
                color: getRandomColor(),
                scale: scale,
                x: x,
                y: y,
                rightBoundary: rightBoundary,
            };

            // console.log(
            //     `${message} || char count: ${getCharacterCount(message)} || ${language} || ${new Blob([message]).size
            //     } bytes || scale: ${scale} || width: ${messageWidth}`,
            // );

            // Add the new message to the message list with its calculated position
            messageQueueRef.current.push(newMessage);
            setMessageQueue(prevMessageQueue => [...prevMessageQueue, newMessage]);
            messageIdRef.current += 1;

            // remove Message after some time
            setTimeout(() => removeMessage(newMessage), timeOutRef.current);

            return newMessage;
        },
        [WALL_SPACE_WIDTH, X_MIN],
    );

    const removeMessage = () => {
        messageQueueRef?.current?.shift();
        setMessageQueue(prevMessages => prevMessages.slice(1));
        if (waitQueueRef.current.length > 0) {
            setRemoveMessageCalled(true);
        }
    };

    useEffect(() => {
        if (nextTime - 3 === currentTime && isRandom) {
            timeOutRef.current = 5000;
            getRandomText().forEach(text => {
                addMessage(text, false);
            });
        }
    }, [addMessage, currentTime, isRandom, nextTime]);

    useEffect(() => {
        if (removeMessageCalled) {
            const firstMessageFromWaitQueue = waitQueueRef.current.shift();
            addMessage(firstMessageFromWaitQueue, true);
        }

        // Reset the state variable
        setRemoveMessageCalled(false);
    }, [addMessage, removeMessageCalled]);

    // Run once on component mount
    useEffect(() => {
        startStatus();

        // Check for empty space every 200ms to push messages from the wait queue faster
        const checkEmptySpaceInterval = setInterval(() => {
            if (waitQueueRef.current.length === 0) return;

            const firstMessageFromWaitQueue = waitQueueRef.current[0];
            if (checkEmptySpace(firstMessageFromWaitQueue)) {
                addMessage(waitQueueRef.current.shift(), true);
            }
        }, 200);
        // Return a cleanup function to stop the interval when the component unmounts
        return () => {
            clearInterval(checkEmptySpaceInterval);
        };
    }, [addMessage, checkEmptySpace, startStatus]);

    const renderMessage = message => {
        return (
            message && (
                <group key={'Group' + message.key}>
                    <Message
                        key={message.key}
                        messageId={message.key}
                        color={message.color}
                        modifiedScale={message.scale}
                        x={message.x}
                        y={message.y}
                        text={message.message}
                        language={message.language}
                        rightBoundary={message.rightBoundary}
                        removeTimeout={timeOutRef.current}
                    />
                </group>
            )
        );
    };

    return <>{messageQueue.map(renderMessage)}</>;
}
