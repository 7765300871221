import { useEffect, useRef } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { BufferGeometry, LoopOnce, Vector3 } from 'three';

import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js';
// import { mergeVertices } from 'three/addons/utils/BufferGeometryUtils.js';

import TimesSquare11_Curtain from './TimesSquare11_Curtain_Subdivision0.glb';

export default function TimesSquareCurtain(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF(TimesSquare11_Curtain);
    const { actions } = useAnimations(animations, group);

    useEffect(() => {
        const playAnimations = () => {
            actions['KeyAction'].setLoop(LoopOnce).reset().play();
        };
        actions['KeyAction'].clampWhenFinished = true;
        playAnimations();

        const handleResize = () => {
            playAnimations();
        };

        const handleVisibilityChange = () => {
            if (!document.hidden) {
                playAnimations();
            }
        };

        window.addEventListener('resize', handleResize);
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [actions]);

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group name="Plane001">
                    <mesh
                        name="Plane006"
                        castShadow
                        receiveShadow
                        geometry={nodes.Plane006.geometry}
                        material={materials['Material.010']}
                        morphTargetDictionary={nodes.Plane006.morphTargetDictionary}
                        morphTargetInfluences={nodes.Plane006.morphTargetInfluences}
                    />
                    <mesh
                        name="Plane006_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.Plane006_1.geometry}
                        material={materials['Material.011']}
                        morphTargetDictionary={nodes.Plane006_1.morphTargetDictionary}
                        morphTargetInfluences={nodes.Plane006_1.morphTargetInfluences}
                    />
                </group>
            </group>
        </group>
    );
}

useGLTF.preload(TimesSquare11_Curtain);
