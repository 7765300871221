import client from '../client';
import { useQuery } from '@tanstack/react-query';

const TextService = {
    useGetText: (fn, param) =>
        useQuery({
            queryKey: ['fetchText'],
            queryFn: async () => {
                return await client.get(`/texts?accessKey=${param}`);
            },
            onSuccess: fn,
            refetchInterval: 100,
        }),
};

export default TextService;
